import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from './functions/utils';

import React from 'react';
import Home from './pages/Home';

const AppHome = () => {

  const state = useLocation().state;

  const [value, setValue] = useState('');
  const colorPrimary = "43, 58, 231";
  const colorSecondary = "87, 89, 117";
  const colorTertiary = "171, 172, 186";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (state !== null) {
      if (state.page !== null) {
        setValue(state.page)
        state.page = null
      }
    }

  }, []);

  const renderContent = () => {
    switch (value) {
      case 'home':
        return <Home onChange={handleChange} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      default:
        setValue("home")
        return <Home />;
    }
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <div>
        {renderContent()}
      </div>
    </ThemeProvider>
  );
};

export default AppHome;
