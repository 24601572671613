import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Grid, Box, TextField, Button, Typography, Paper, useMediaQuery, CircularProgress, Divider } from '@mui/material';
import { ColorlibStepIcon, ColorlibConnector } from '../components/utils';
import { useState } from 'react';
import { AttachMoneyOutlined, DownloadingOutlined, TaskAltOutlined } from '@mui/icons-material';

// Etapas do Stepper
const steps = [
  'Consulte seu CPF/CNPJ e veja se possui dívidas.',
  'Veja as opções de negociação e faça um acordo.',
  'Pronto! Agora é só pagar e voltar a ter crédito.',
];

const Home = ({ colorPrimary, colorSecondary, colorTertiary }) => {

  const isMobile = useMediaQuery('(max-width:899px)');
  const [loadingConsultar, setLoadingConsultar] = useState(false);
  const [loadingBoleto, setLoadingBoleto] = useState(false);
  const [loadingBaixarBoleto, setLoadingBaixarBoleto] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const handleConsultar = () => {
    setLoadingConsultar(true)
    setTimeout(() => {
      setCurrentStep(1);
      setLoadingConsultar(false);
    }, 2000)
  }

  const handleGerarBoleto = () => {
    setLoadingBoleto(true)
    setTimeout(() => {
      setCurrentStep(2);
      setLoadingBoleto(false);
    }, 2000)
  }

  const handleBaixarBoleto = () => {
    setLoadingBaixarBoleto(true)
    setTimeout(() => {
      setLoadingBaixarBoleto(false);
    }, 2000)
  }

  const handleVoltarAoInicio = () => {
    setCurrentStep(0);
  }

  return (
    <Grid container sx={{ 
      height: '100%', 
      minHeight: "100vh", 
      padding: '20px', 
      background: isMobile ? "#2b3ae7" : "",
      backgroundImage: isMobile 
        ? "#2b3ae7" 
        : "linear-gradient(115deg, #2b3ae7 50%, #2b3ae700 60%), url('https://elysolucoestecnologicas.com.br/images/bg-pt.png')",
      backgroundSize: 'cover',
      backgroundPosition: 'center, calc(100% + 450px) center',
    }}>
      {/* Lado Esquerdo - Grid de Círculos de Steps */}
      <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
        <Box style={{ textAlign: "center", color: "white" }}>
          <img src='https://crm.elysolucoestecnologicas.com.br/imagens/logo-2.png' style={{ height: 80 }} />
          <Typography variant="h4" gutterBottom align="center">
            Veja como é facil quitar sua dívida!
          </Typography>
          <div style={{ height: 30 }} />
          <Stepper alternativeLabel activeStep={currentStep} connector={<ColorlibConnector />}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  <Typography style={{ color: "#fff" }}>
                    {index === 2 ? <><b>Pronto!</b> <br />Agora é só pagar e voltar a ter crédito. </> : label}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Grid>

      {/* Lado Direito - Caixa de Input CPF */}
      <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center" style={{ marginTop: isMobile ? 30 : 0 }}>
        <Box
          component={Paper}
          p={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          boxShadow={3}
          borderRadius={2}
          style={{ width: 400 }}
        >

          {currentStep === 0 &&
            <>
              <Typography variant="h5" gutterBottom>
                Digite seu CPF/CNPJ
              </Typography>
              <TextField
                variant="outlined"
                label="CPF/CNPJ"
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 14 }}
                type="text"
              />
              <div style={{ height: 20 }} />
              <Button variant="contained" color="primary" size="large" fullWidth onClick={handleConsultar}>
                {loadingConsultar ?
                  <CircularProgress sx={{ color: "white" }} size={26} />
                  :
                  <>Consultar dívida</>
                }
              </Button>
            </>
          }

          {currentStep === 1 &&
            <div style={{ width: "100%" }}>
              <Typography variant="h5" gutterBottom>
                Olá, <b style={{ textTransform: "uppercase" }}>Joelson Lucas da Silva</b>
              </Typography>
              <Divider />
              <div style={{ height: 10 }} />
              <Typography style={{ fontWeight: "600", fontSize: 18, display: "flex", background: "#F2F2F2", paddingTop: 5, paddingBottom: 5 }}><AttachMoneyOutlined /> &nbsp;&nbsp;Dívida</Typography>
              <div style={{ paddingLeft: 6, paddingRight: 6 }}>

                <div style={{ height: 15 }} />
                <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                  Vencimento
                  <b style={{ marginLeft: "auto" }}>14/07/2020</b>
                </Typography>

                <div style={{ height: 2 }} />
                <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                  Valor da Dívida
                  <b style={{ marginLeft: "auto" }}>R$  100,00</b>
                </Typography>

                <div style={{ height: 2 }} />
                <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                  Juros e Multa
                  <b style={{ marginLeft: "auto" }}>R$  0,00</b>
                </Typography>

                <div style={{ height: 2 }} />
                <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14, color: "green" }}>
                  Desconto
                  <b style={{ marginLeft: "auto" }}>R$ 10,00</b>
                </Typography>
                <div style={{ height: 10 }} />
                <Divider />
                <div style={{ height: 10 }} />
                <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 17 }}>
                  Valor Total
                  <b style={{ marginLeft: "auto" }}>R$ 10,00</b>
                </Typography>

                <div style={{ height: 10 }} />
                <Divider />
                <div style={{ height: 18 }} />

                <Button variant="contained" color="primary" size="large" fullWidth onClick={handleGerarBoleto}>
                  {loadingBoleto ?
                    <CircularProgress sx={{ color: "white" }} size={26} />
                    :
                    <>Gerar boleto</>
                  }
                </Button>
              </div>
            </div>
          }

          {currentStep === 2 &&
            <>
              <Typography style={{ fontSize: 16, textAlign: "center" }}>
                <TaskAltOutlined style={{ color: "green", fontSize: 62 }} /><br />
                <b style={{ fontSize: 20 }}>Parabéns, <b style={{ textTransform: "uppercase" }}>Joelson Lucas da Silva</b></b><br />

                <b style={{ fontWeight: "normal", fontSize: 13, color: "#888" }}>Você está a um passo de regularizar suas dívidas!
                  <br />
                  O boleto foi enviado para o seu e-mail cadastrado, mas você também pode baixar clicando no botão abaixo.</b>
              </Typography>
              <div style={{ height: 18 }} />
              <Button variant="contained" color="primary" size="large" fullWidth startIcon={loadingBaixarBoleto ? <></> : <DownloadingOutlined />} onClick={handleBaixarBoleto}>
                {loadingBaixarBoleto ?
                  <CircularProgress sx={{ color: "white" }} size={26} />
                  :
                  <>Baixar Boleto</>
                }
              </Button>

              <div style={{ height: 18 }} />
              <Button variant="contained" color="secondary" size="large" fullWidth onClick={handleVoltarAoInicio}>
                Voltar ao Inicio
              </Button>
            </>
          }
        </Box>
      </Grid>
      <Typography style={{ color: "white", width: isMobile ? "100vw" : "", position: isMobile ? "relative" : "absolute", bottom: 0, left: 0, right: 0, marginTop: isMobile ? 100 : 0, textAlign: isMobile ? "center" : "left" }}>
        © 2024 ELY - Desenvolvido por ELY Soluções Tecnologicas Ltda.
      </Typography>
    </Grid>
  );
};

export default Home;
